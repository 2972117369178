import {
  currentUser,
  salesPerEstabelecimentoUrl,
  vendasTotalSalesPerEstablishment,
  vendasDetailsByNsu,
  vendasInstallments,
  vendasListNumberSerie,
  vendasListNumberFlag,
  pixGenerate,
  pixItauPerEstabelecimento,
  pixSalesPerEstablishment,
  vendasListNumberSerieGPOS,
  pixEstornar,
  countSalesPerEstabelecimentoUrl,
  pixGenerateWithTokenSession,
  pixEstornarWithTokenSession, anchor2CardReportUrl
} from "../constants/config";
import axios from 'axios'

const config = currentUser
  ? { headers: { Authorization: currentUser.token } }
  : { headers: { Authorization: ""} };

export const salesDetailsService = (url) => {
  return axios.get(url, config)
}

export const salesPerEstabelecimentoService = (groupId, filter) => {
  let url = salesPerEstabelecimentoUrl + groupId
  if (filter) {
    url += filter
  }
  return axios.get(url, config)
}

export const salesTotalPerEstablishment = (user, filters) => {
  return axios.get(vendasTotalSalesPerEstablishment + user.grupo_id + filters, config)
}

export const salesInstallments = (user, nsu, filters) => {
  return axios.get(vendasInstallments + user.grupo_id + '/' + nsu + filters, config)
}

export const salesDetailsByNsu = (user, nsu, filter) => {
  return axios.get(vendasDetailsByNsu + user.grupo_id + '/' + nsu + filter + '&plataform=web', config)
}

export const listNumberSerie = (grupo_id, filter) => {
  return axios.get(vendasListNumberSerie + grupo_id + filter, config)
}

export const listNumberSerieGPOS700 = (grupo_id, filter) => {
  return axios.get(vendasListNumberSerieGPOS + grupo_id + filter, config)
}

export const listFlags = () => {
  return axios.get(vendasListNumberFlag, config)
}

export const getSalesNSU = (user, filter, type) => {
  return axios.get(salesPerEstabelecimentoUrl + user.grupo_id + filter + "&type=" + type + '&plataform=web', config)
}

export const getSalesCount = (user, filter) => {
  return axios.get(countSalesPerEstabelecimentoUrl + user.grupo_id + filter + '&plataform=web', config)
}

export const postPixGenerate = (data) => {
  return axios.post(pixGenerateWithTokenSession, data, config)
}

export const postPixEstornar = (txid, establishmentId) => {
  return axios.post(pixEstornarWithTokenSession + "/" + txid + "/" + establishmentId, {}, config)
}

export const getPixItauPerEstabelecimento = (grupo_id, filters) => {
  return axios.get(pixItauPerEstabelecimento + grupo_id + filters, config)
}

export const getChargebacks = (url) => {
  return axios.get(url, config);
}

export const getAnchor2CardReport = (user, filter) => {
  return axios.get(anchor2CardReportUrl + user.grupo_id + filter + '&plataform=web', config)
}
